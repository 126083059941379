import mitt from 'mitt';
import { UnwrapNestedRefs } from 'vue';
import Source from '../app/crm/models/Source';
import InvoiceForm from '~/app/invoicing/classes/InvoiceForm';
import Invoice from '~/app/invoicing/models/Invoice';
import Contact from '~~/app/contact/models/Contact';
import Lead from '~~/app/crm/models/Lead';
import CalendarEvent from '~~/app/planning/models/CalendarEvent';
import TimeActivity from '~~/app/planning/models/TimeActivity';
import Task from '~~/app/task/models/Task';
import Worksite from '~~/app/worksite/models/Worksite';
import ModelFile from '~~/app/common/models/ModelFile';
import Tenant from '~/app/auth/models/Tenant';
import BankAccountTransaction from '~/app/bankaccount/models/BankAccountTransaction';
import InvoiceTemplateSection from '@/app/invoicing/models/InvoiceTemplateSection';
import InvoiceTemplate from '@/app/invoicing/models/InvoiceTemplate';
import InvoiceTemplateSectionColumn from '@/app/invoicing/models/InvoiceTemplateSectionColumn';
import InvoiceSection from '@/app/invoicing/models/InvoiceSection';
import InvoiceAnnexPdf from '@/app/invoicing/models/InvoiceAnnexPdf';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export type ApplicationEvents = {
    'modal:open': string;
    'modal:close': string;

    'contact:contact:context-menu:open': { model: Contact; event: Event };
    'contact:contact:context-menu:close': void;
    'contact:contact:modal:open': ModalPayload<Contact>;
    'contact:contact:modal:close': void;
    'contact:contact:form-modal:open': ModalPayload<Contact>;
    'contact:contact:form-modal:close': void;
    'contact:contact:created': Contact;
    'contact:contact:updated': Contact;
    'contact:contact:deleted': Contact;
    'contact:contact:destroy-alert': { callback: () => void; model: Contact };

    'crm:lead:context-menu:open': { model: Lead; event: Event };
    'crm:lead:context-menu:close': void;
    'crm:lead:modal:open': ModalPayload<Lead>;
    'crm:lead:modal:close': void;
    'crm:lead:form-modal:open': any;
    'crm:lead:form-modal:close': void;
    'crm:lead:quote-modal:open': any;
    'crm:lead:quote-modal:close': void;
    'crm:lead:created': Lead;
    'crm:lead:updated': Lead;
    'crm:lead:deleted': Lead;

    'crm:source:form-modal:open': any;
    'crm:source:form-modal:close': void;
    'crm:source:created': Source;
    'crm:source:updated': Source;
    'crm:source:deleted': Source;

    'invoicing:invoice:preview-html:open': {
        invoice: Invoice;
        form: InvoiceForm<any>;
    };
    'invoicing:invoice:preview-html:close': void;
    'invoicing:invoice:link-to-worksite:open': { invoice: Invoice };
    'invoicing:invoice:link-to-worksite:close': void;
    'invoicing:invoice:sent': Invoice;
    'invoicing:invoice-templates:created': InvoiceTemplate;
    'invoicing:invoice-templates:updated': InvoiceTemplate;
    'invoicing:invoice-templates:deleted': InvoiceTemplate;
    'invoicing:invoice-templates:form-modal:open': any;
    'invoicing:invoice-templates:form-modal:close': void;
    'invoicing:invoice-template-sections:created': InvoiceTemplateSection;
    'invoicing:invoice-template-sections:updated': InvoiceTemplateSection;
    'invoicing:invoice-template-sections:deleted': InvoiceTemplateSection;
    'invoicing:invoice-template-sections:form-modal:open': any;
    'invoicing:invoice-template-sections:form-modal:close': void;
    'invoicing:invoice-template-section-columns:created': InvoiceTemplateSectionColumn;
    'invoicing:invoice-template-section-columns:updated': InvoiceTemplateSectionColumn;
    'invoicing:invoice-template-section-columns:deleted': InvoiceTemplateSectionColumn;
    'invoicing:invoice-template-section-columns:form-modal:open': any;
    'invoicing:invoice-template-section-columns:form-modal:close': void;
    'invoicing:invoice-form:edit-disable:open': void;
    'invoicing:invoice-form:edit-disable:close': void;
    'invoicing:invoice-form:line:open': { section: InvoiceSection | undefined };
    'invoicing:invoice-form:line:add': { section: InvoiceSection | undefined };
    'invoicing:invoice-form:line:add-composed-work': { section: InvoiceSection | undefined };
    'invoicing:invoice-annex-pdf:annex-pdf-modal:open': any;
    'invoicing:invoice-annex-pdf:annex-pdf-modal:close': void;
    'invoicing:invoice-annex-pdf:annex-pdf-modal:created': InvoiceAnnexPdf;
    'invoicing:invoice-annex-pdf:annex-pdf-modal:updated': InvoiceAnnexPdf;

    'invoicing:invoice:export-modal:open': any;
    'invoicing:invoice:export-modal:close': void;
    'invoicing:invoice-form:data:open': any;
    'invoicing:invoice-form:data:close': any;

    'task:task:context-menu:open': { model: Task; event: Event };
    'task:task:context-menu:close': void;
    'task:task:modal:open': ModalPayload<Task>;
    'task:task:modal:close': void;
    'task:task:form-modal:open': any;
    'task:task:form-modal:close': void;
    'task:task:created': Task;
    'task:task:updated': Task;
    'task:task:deleted': Task;

    'tenancy:tenant:mode:modal:open': UnwrapNestedRefs<Tenant>;

    'planning:time-activity:context-menu:open': {
        model: TimeActivity;
        event: Event;
    };
    'planning:time-activity:context-menu:close': void;
    'planning:time-activity:modal:open': ModalPayload<TimeActivity>;
    'planning:time-activity:modal:close': void;
    'planning:time-activity:form-modal:open': any;
    'planning:time-activity:form-modal:close': void;
    'planning:time-activity:created': TimeActivity;
    'planning:time-activity:updated': TimeActivity;
    'planning:time-activity:deleted': TimeActivity;

    'planning:time-activity:link-invoice:open': ModalPayload<TimeActivity>;
    'planning:time-activity:link-invoice:close': void;
    'planning:time-activity:invoice-multiple:open': any;
    'planning:time-activity:invoice-multiple:close': void;

    'planning:calendar-event:context-menu:open': {
        model: CalendarEvent;
        event: Event;
    };

    'planning:calendar-event:context-menu:close': void;
    'planning:calendar-event:modal:open': ModalPayload<CalendarEvent>;
    'planning:calendar-event:modal:close': void;
    'planning:calendar-event:form-modal:open': any;
    'planning:calendar-event:form-modal:close': void;
    'planning:calendar-event:created': CalendarEvent;
    'planning:calendar-event:updated': CalendarEvent;
    'planning:calendar-event:deleted': CalendarEvent;

    'worksite:worksite:context-menu:open': { model: Worksite; event: Event };
    'worksite:worksite:context-menu:close': void;
    'worksite:worksite:form-modal:open': any;
    'worksite:worksite:form-modal:close': void;
    'worksite:worksite:created': Worksite;
    'worksite:worksite:updated': Worksite;
    'worksite:worksite:deleted': Worksite;
    'worksite:worksite:collection:updated': void;

    'common:model-file:context-menu:open': {
        model: ModelFile;
        event: Event;
    };
    'common:model-file:context-menu:close': void;
    'common:model-file:modal:open': ModalPayload<ModelFile>;
    'common:model-file:modal:close': void;
    'common:model-file:form-modal:open': any;
    'common:model-file:form-modal:upload': any;
    'common:model-file:form-modal:close': void;
    'common:model-file:created': ModelFile;
    'common:model-file:updated': ModelFile;
    'common:model-file:deleted': ModelFile;
    'common:meilisearch-hit:context-menu:open': {
        event: MouseEvent;
        uuid: string;
        modelType: ModelType;
    };
    'common:meilisearch-hit:context-menu:close': void;
    'common:files:export-modal:open': void;
    'common:files:export-modal:close': void;

    'app:search:open': void;
    'app:search:close': void;
    'app:konami-code-modal:open': void;
    'app:konami-code-modal:close': void;

    'app:coupons:open': void;
    'app:coupons:close': { coupons: string[] } | void;

    'bankaccount:bank-account-transaction:assign-invoice:open': {
        transaction: BankAccountTransaction;
    };
    'bankaccount:bank-account-transaction:assign-invoice:close': void;

    'tenancy:tenants:spots-modal:open': {
        tenant: Tenant;
    };
    'tenancy:tenants:spots-modal:close': void;
    'settings:tenants:subscription:open': {
        tenant: Tenant;
        callback: () => void;
    };
    'settings:tenants:subscription:close': void;

    'invoicing:composed-work:upsell-modal:open': void;
    'invoicing:composed-work:upsell-modal:close': void;
    'invoicing:invoice-form:waste-data:open': any;
    'invoicing:invoice-form:waste-data:close': any;
    'common:waste-center:form-modal:open': void;
    'common:waste-center:form-modal:close': void;
    'worksite:worksite:closure-report:form-modal:open': { worksite?: Worksite };
    'worksite:worksite:closure-report:form-modal:close': void;
    'worksite:worksite:closure-report:send-email:open': any;
    'worksite:worksite:closure-report:send-email:close': void;
    'worksite:worksite:closure-report:mark-as-sent:open': any;
    'worksite:worksite:closure-report:mark-as-sent:close': void;
    'worksite:worksite:closure-reports:updated': any;
    'worksite:worksite:closure-reports:deleted': any;
    'worksite:worksite:closure-reports:created': any;
    'worksite:worksite-progress-billing:quote-edit-disable-info:open': void;
    'worksite:worksite-progress-billing:quote-edit-disable-info:close': { isConfirmed: boolean };
};

const emitter = mitt<ApplicationEvents>();

export const useEvent = emitter.emit;
export const useListen = emitter.on;
export const useIgnore = emitter.off;
